import collect from "collect.js";

const priceFilter = ref([0, 9999])
const selectedFilters = ref({});
const activeFilters = ref({})
const isLoadingFilters = ref(false)

export default () => {
    const router = useRouter()
    const route = useRoute()
    const {locale} = useI18n()
    const {status, data:customerData} = useAuth()
    const {t} = useI18n()

    const {totalResults} = usePagination()

    const filters = useState('filters', () => [])
    const categories = useState('categories', () => [])
    const sortOptions = useState('sortOptions', () => [])
    const selectedSortOption = useState('selectedSortOption', () => Object.assign({}))

    // Facets
    const facets = useState('facets');
    const availableSearchFields = useState('wholesale::availableSearchFields')
    const categoryFacets = useState('categoryFacets')
    const brandFacets = useState('brandFacets')
    const sizeFacets = useState('sizeFacets')
    const minPriceFacet = useState('minPriceFacet')
    const maxPriceFacet = useState('maxPriceFacet')
    const attributeFacets = useState('attributeFacets')
    const attributeItemFacets = useState('attributeItemFacets')
    const colorFacets = useState('colorFacets')

    // Search options
    const colorLabel = useState('colorLabel')
    const sizeLabel = useState('sizeLabel')
    const isSearchAction = useState('isSearchAction')

    const minPrice = computed(() => minPriceFacet.value ? minPriceFacet.value : 0)
    const maxPrice = computed(() => maxPriceFacet.value ? maxPriceFacet.value : 9999)
    const mergePriceValue = computed(() => maxPriceFacet.value ? maxPriceFacet.value * 0.25 : 250)
    const marginPriceValue = computed(() => maxPriceFacet.value > 1000 ? 100 : 10)

    const filterQuery = useState("filterQuery", () => Object.assign({}))

    const getAllCustomFilters = useState('getAllCustomFilters', () => 0)
    const filterKey = computed(() => `filters::${route.params.slug ? route.params.slug : 'search'}::${locale.value}`)

    const headers = useRequestHeaders(['cookie'])

    const isWholesaleUser = computed(() => status?.value === 'authenticated' && customerData?.value?.customer_type === 1)

    const getFilterOptions = async () => {
        isLoadingFilters.value = true
        const {data, error, refresh} = await useAsyncData(filterKey.value, () => $fetch('/api/filters', {
            method: 'GET',
            query: {
                slug: route.params.slug,
                lang: locale.value,
                getAllCustomFilters: getAllCustomFilters.value
            },
            headers
        }))

        if (data.value) {
            filters.value = data.value.filter((i) => i.queryName !== "category" && i.queryName !== "sort")

            categories.value = data.value.filter((i) => i.queryName === "category")[0]

            sortOptions.value = data.value.filter((i) => i.queryName === "sort")[0]?.options;


            if (
                data.value.filter((i) => i.queryName === "sort")?.length && typeof data.value.filter((i) => i.queryName === "sort")[0]['default'] !== "undefined"
            ) {
                selectedSortOption.value = data.value.filter((i) => i.queryName === "sort")[0]['default']
            } else {
                selectedSortOption.value = {
                    label : t('listing.recommended-order'),
                    value : 0,
                    code : 'default',
                    direction : null
                };
            }

        }

        isLoadingFilters.value = false
    }

    const setSelectedFilters = (item) => {
        selectedFilters.value = item;
    };

    const checkVisible = (queryName, option = null) => {
        if (totalResults.value === 0) {
            return true;
        }

        if (queryName === 'brand') {
            if (option) {
                return brandFacets.value && typeof brandFacets.value[option.id] !== 'undefined'
            } else {
                return !Array.isArray(brandFacets.value) && typeof brandFacets.value === 'object' && Object.keys(brandFacets.value).length
            }
        } else if (queryName === 'color') {
            if (option) {
                return colorFacets.value && (option && typeof colorFacets.value[option.value] !== 'undefined')
            } else {
                return !Array.isArray(colorFacets.value) && typeof colorFacets.value === 'object' && Object.keys(colorFacets.value).length
            }
        } else if (queryName === 'category') {
            if (option) {
                return categoryFacets.value && (option && typeof categoryFacets.value[option.id] !== 'undefined')
            } else {
                return !Array.isArray(categoryFacets.value) && typeof categoryFacets.value === 'object' && Object.keys(categoryFacets.value).length
            }
        } else if (queryName === 'size') {
            if (option) {
                return sizeFacets.value && (option && typeof sizeFacets.value[option.value] !== 'undefined')
            } else {
                return !Array.isArray(sizeFacets.value) && typeof sizeFacets.value === 'object' && Object.keys(sizeFacets.value).length
            }
        } else if (queryName === 'price') {
            return minPriceFacet.value >= 0 && maxPriceFacet.value >= 1
        } else {
            if (option) {
                return attributeItemFacets.value && typeof attributeItemFacets.value[option.value] !== 'undefined'
            } else {
                return !Array.isArray(attributeItemFacets.value) && typeof attributeItemFacets.value === 'object' && Object.keys(attributeItemFacets.value).length
            }
        }
    }

    const setPriceFilter = (value) => {
        activeFilters.value['price'] = value.join(",")

        if (typeof window !== 'undefined') window.scrollTo(0, 0);
    }

    const filterItem = (item, section) => {

        if (selectedFilters.value[section.queryName]) {
            selectedFilters.value[section.queryName].includes(item.value.toString()) ? selectedFilters.value[section.queryName] = selectedFilters.value[section.queryName].filter(el => el != item.value.toString()) : selectedFilters.value[section.queryName].push(item.value.toString())
        } else {
            selectedFilters.value[section.queryName] = [item.value]
        }

        setSelectedFilters(selectedFilters.value)
        updateFilters(item, section, selectedFilters.value);
    };

    const updateFilters = (filterItem, section, filteredValue) => {
        if (filteredValue[section.queryName].length) {
            filterQuery.value[section.queryName] = filteredValue[section.queryName].join(',')
        }

        activeFilters.value[section.queryName] = filteredValue[section.queryName];

        // Sayfa tekrar yenilendiğinde eski değerleri de url yazdırma
        for (const [key, value] of Object.entries(activeFilters.value)) {
            if (activeFilters.value[key] !== filterQuery.value[key]) {
                filterQuery.value[key] = value.join(',')
            }
        }

        setQuery(filterQuery.value);
    };

    const setQuery = async (value) => {
        // URL Replace
        router.push({
            query: {
                ...route.query,
                ...value
            },
        });
    };

    const resetFilters = () => {
        // router.push({path : route.path, query: null});
        window.history.pushState({}, document.title, window.location.pathname);
        filterQuery.value = {}
        selectedFilters.value = {}
        activeFilters.value = {}
        facets.value = null
    }

    /*
      Özel alanların sıralama verisine dahil edilebilmesi için burada özel olarak tanımlamalar yapıyoruz
     */
    const availableSortableFields = computed(() => {
        const array = collect(availableSearchFields.value)
            .whereIn('value', ['base::qty', 'base::listPrice', 'base::creditCardPrice', 'base::wholesalePrice', 'base::cashPrice'])
            .toArray()

        if (!isWholesaleUser.value) {
            return []
        }

        return array.reduce((acc, i) => {
            let ascObject = {}
            let descObject = {}

            if (i.value === 'base::listPrice') { // wholesaleListPrice
                descObject.label = `${t('listing.list-price')} (${t('app.descending')})`
                descObject.value = 18
                descObject.code = 'base::listPrice'
                descObject.direction = 'desc'

                ascObject.label = `${t('listing.list-price')} (${t('app.ascending')})`
                ascObject.value = 17
                ascObject.code = 'base::listPrice'
                ascObject.direction = 'asc'
            } else if (i.value === 'base::creditCardPrice') { // wholesaleCardPrice
                descObject.label = `${t('listing.wholesale-card-price')} (${t('app.descending')})`
                descObject.value = 16
                descObject.code = 'base::creditCardPrice'
                descObject.direction = 'desc'

                ascObject.label = `${t('listing.wholesale-card-price')} (${t('app.ascending')})`
                ascObject.value = 15
                ascObject.code = 'base::creditCardPrice'
                ascObject.direction = 'asc'
            } else if (i.value === 'base::wholesalePrice') { // wholesalePrice
                descObject.label = `${t('listing.wholesale-price')} (${t('app.descending')})`
                descObject.value = 11
                descObject.code = 'base::wholesalePrice'
                descObject.direction = 'desc'

                ascObject.label = `${t('listing.wholesale-price')} (${t('app.ascending')})`
                ascObject.value = 12
                ascObject.code = 'base::wholesalePrice'
                ascObject.direction = 'asc'
            } else if (i.value === 'base::cashPrice') { // wholesalePrice - ! isWholesaleUser => price
                descObject.label = `${t('listing.wholesale-cash-price')} (${t('app.descending')})`
                descObject.value = 14
                descObject.code = 'base::cashPrice'
                descObject.direction = 'desc'

                ascObject.label = `${t('listing.wholesale-cash-price')} (${t('app.ascending')})`
                ascObject.value = 13
                ascObject.code = 'base::cashPrice'
                ascObject.direction = 'asc'
            } else {
                return acc;
            }

            return acc.concat([ascObject, descObject])
        }, [])
    })

    const customSortOptions = computed(() => [
        {
            label : t('listing.recommended-order'),
            value : 0,
            code : 'default',
            direction : null
        },
        {
            label : `${t('listing.piece')} (${t('app.descending')})`,
            value : 7,
            code : 'base::qty',
            direction : 'desc'
        },
        {
            label : `${t('listing.piece')} (${t('app.ascending')})`,
            value : 8,
            code : 'base::qty',
            direction: 'asc'
        },
        ...availableSortableFields.value
    ])

    return {
        filters,
        categories,
        sortOptions,
        selectedSortOption,
        categoryFacets,
        brandFacets,
        sizeFacets,
        minPriceFacet,
        maxPriceFacet,
        attributeFacets,
        attributeItemFacets,
        colorFacets,
        minPrice,
        maxPrice,
        mergePriceValue,
        marginPriceValue,
        activeFilters,
        filterQuery,
        getAllCustomFilters,
        filterKey,
        selectedFilters,
        priceFilter,
        availableSortableFields,
        customSortOptions,
        isLoadingFilters,
        colorLabel,
        sizeLabel,
        isSearchAction,
        getFilterOptions,
        setSelectedFilters,
        checkVisible,
        setPriceFilter,
        filterItem,
        resetFilters,
        updateFilters
    }
}